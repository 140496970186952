import React from 'react'

const SimpleSvg = (props) => {
    return (
        <svg width={props.width} height={props.height}  viewBox="0 0 59 58" fill="none" xmlns="http://www.w3.org/2000/svg">
            <title>{props.alt}</title>
            <path fillRule="evenodd" clipRule="evenodd" d="M52.0801 19.5163C52.1629 19.0104 52.2084 18.5005 52.2084 17.9905C52.2084 12.2768 51.2048 0.849429 35.086 0.849426C21.2669 0.849424 18.5343 11.1341 18.5343 16.8478C18.5343 22.5615 23.671 29.4179 23.671 29.4179C23.671 29.4179 19.105 31.7034 13.9683 31.7034C8.83158 31.7034 7.43108 29.4179 3.85072 28.8466C0.270365 28.2752 -0.871131 45.9877 4.83635 52.2728C10.5438 58.5578 17.9635 57.9865 29.3785 57.9865C40.7935 57.9865 57.9159 57.9865 57.9159 46.5591C57.9159 35.1316 46.5009 29.4179 46.5009 29.4179C46.5009 29.4179 47.6381 28.2795 48.8973 26.5108C49.0778 26.5111 49.2642 26.5112 49.457 26.5112C54.5459 26.5112 62.0259 20.2155 55.7415 20.2155C54.4389 20.2155 53.21 19.9451 52.0801 19.5163ZM41.4145 17.9946C42.9901 17.9946 44.2673 16.7152 44.2673 15.1371C44.2673 13.5589 42.9901 12.2795 41.4145 12.2795C39.839 12.2795 38.5618 13.5589 38.5618 15.1371C38.5618 16.7152 39.839 17.9946 41.4145 17.9946Z" fill="#3A3A3A"/>
            <rect x="35" y="9" width="10" height="14" fill="#3A3A3A"/>
            <path d="M43 16C42.6471 11.4972 37 12.5363 37 16" stroke="white" strokeWidth="2" strokeLinecap="round"/>
        </svg>
    )
}

export default SimpleSvg