import React from 'react'

const SocialSvg = () => {
    return(
        <svg  viewBox="0 0 62 59" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.0973 36.9426C16.2286 36.9426 19.5776 33.5935 19.5776 29.4623C19.5776 25.331 16.2286 21.9819 12.0973 21.9819C7.966 21.9819 4.61694 25.331 4.61694 29.4623C4.61694 33.5935 7.966 36.9426 12.0973 36.9426Z" fill="#3A3A3A"/>
            <path d="M14.9104 38.9885H9.34813C5.32026 38.9885 1.93173 41.9934 1.48419 46.0213L0.653042 52.4148C0.525173 53.2459 1.10058 54.0131 1.93173 54.2049C5.12846 54.9082 8.58092 55.2279 12.0973 55.2279C13.5678 55.2279 14.9744 55.1639 16.317 55.0361L17.0203 49.282C17.4039 46.2771 18.8744 43.7197 21.0481 41.9295C19.6416 40.1393 17.3399 38.9885 14.9104 38.9885Z" fill="#3A3A3A"/>
            <path d="M49.9464 36.9426C54.0777 36.9426 57.4267 33.5935 57.4267 29.4623C57.4267 25.331 54.0777 21.9819 49.9464 21.9819C45.8151 21.9819 42.4661 25.331 42.4661 29.4623C42.4661 33.5935 45.8151 36.9426 49.9464 36.9426Z" fill="#3A3A3A"/>
            <path d="M60.6237 45.9574C60.1762 41.9295 56.7237 38.9246 52.7598 38.9246H47.1975C44.7041 38.9246 42.4663 40.0754 40.9958 41.9295C43.1696 43.7197 44.7041 46.2771 45.0237 49.282L45.727 55.0361C47.0696 55.164 48.5401 55.2279 49.9467 55.2279C53.527 55.2279 56.9155 54.8443 60.1123 54.205C60.9434 54.0132 61.4549 53.2459 61.3909 52.4148L60.6237 45.9574Z" fill="#3A3A3A"/>
            <path d="M33.7713 42.7606H28.209C24.1811 42.7606 20.7926 45.7655 20.3451 49.7295L19.5778 56.1869C19.45 57.018 20.0254 57.8492 20.8565 57.977C24.0533 58.6803 27.5057 59 31.0221 59C34.6024 59 37.991 58.6164 41.1877 57.977C42.0189 57.7852 42.5303 57.018 42.4664 56.1869L41.6992 49.7295C41.1877 45.7655 37.7992 42.7606 33.7713 42.7606Z" fill="#3A3A3A"/>
            <path d="M31.0218 40.7148C35.1531 40.7148 38.5022 37.3657 38.5022 33.2344C38.5022 29.1031 35.1531 25.7541 31.0218 25.7541C26.8906 25.7541 23.5415 29.1031 23.5415 33.2344C23.5415 37.3657 26.8906 40.7148 31.0218 40.7148Z" fill="#3A3A3A"/>
            <path d="M33.3877 17.9541C36.009 19.2967 39.2057 19.2328 41.891 17.8262L45.727 19.1049C46.5582 19.3607 47.2615 18.5935 47.0057 17.8262L45.727 14.0541C47.5172 10.5377 47.0057 6.12623 44.0648 3.18524C41.1877 0.308193 36.8402 -0.267217 33.3877 1.52295C37.6713 6.12623 37.6713 13.2869 33.3877 17.9541Z" fill="#3A3A3A"/>
            <path d="M16.3809 19.1049L20.217 17.8262C23.6695 19.6803 28.0809 19.1689 31.0219 16.2279C34.6023 12.6476 34.6023 6.76558 31.0219 3.18525C27.4416 -0.395083 21.5596 -0.395083 17.9793 3.18525C15.0383 6.12623 14.5268 10.5377 16.3809 13.9902L15.1022 17.8262C14.7826 18.5935 15.5498 19.3607 16.3809 19.1049Z" fill="#3A3A3A"/>
        </svg>
    )
}

export default SocialSvg
