import React from 'react'

const PrivateSvg = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
            <title>{props.alt}</title>
            <path d="M29.3335 17.8667C27.6135 17.8667 26.4668 19.0134 26.4668 20.7334V23.6H32.2001V20.7334C32.2001 19.0134 31.0535 17.8667 29.3335 17.8667Z" fill="#3A3A3A"/>
            <path d="M29.3335 36.5C30.9167 36.5 32.2001 35.2165 32.2001 33.6333C32.2001 32.0501 30.9167 30.7667 29.3335 30.7667C27.7502 30.7667 26.4668 32.0501 26.4668 33.6333C26.4668 35.2165 27.7502 36.5 29.3335 36.5Z" fill="#3A3A3A"/>
            <path d="M29.3334 0.666687C13.5667 0.666687 0.666748 13.5667 0.666748 29.3334C0.666748 45.1 13.5667 58 29.3334 58C45.1001 58 58.0001 45.1 58.0001 29.3334C58.0001 13.5667 45.1001 0.666687 29.3334 0.666687ZM40.8001 40.8C40.8001 42.52 39.6534 43.6667 37.9334 43.6667H20.7334C19.0134 43.6667 17.8667 42.52 17.8667 40.8V26.4667C17.8667 24.7467 19.0134 23.6 20.7334 23.6V20.7334C20.7334 15.86 24.4601 12.1334 29.3334 12.1334C34.2067 12.1334 37.9334 15.86 37.9334 20.7334V23.6C39.6534 23.6 40.8001 24.7467 40.8001 26.4667V40.8Z" fill="#3A3A3A"/>
        </svg>
    )
}

export default PrivateSvg

