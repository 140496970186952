import React from 'react'

const OpenSvg = (props) => {
    return(
    <svg width={props.width} height={props.height} viewBox="0 0 67 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>{props.alt}</title>
        <path d="M59.5343 41.8083C59.9507 41.9736 60.4503 41.9323 60.8389 41.6845C61.2136 41.4231 61.4495 40.9964 61.4495 40.5285V4.74603C61.4495 4.1955 61.1165 3.70007 60.603 3.47999C52.165 -0.0570655 43.5606 -0.304696 35.0811 2.73676V41.4505C43.1165 38.2852 51.3046 38.409 59.5343 41.8083Z" fill="#3A3A3A"/>
        <path d="M65.6123 8.72327H64.2245V40.5289C64.2245 41.8782 63.5577 43.1497 62.4409 43.9332C61.6603 44.4318 60.8728 44.6603 60.061 44.6603C59.523 44.6603 58.9998 44.5608 58.5038 44.3646C54.5599 42.7357 50.6296 41.9172 46.786 41.9172C43.2216 41.9172 39.6287 42.6215 36.105 44.0085L35.0804 44.4122L33.6926 44.959L32.3047 44.4122L31.2801 44.0085C27.7564 42.6215 24.1635 41.9172 20.6005 41.9172C16.7569 41.9172 12.8265 42.7357 8.91925 44.3498C8.38663 44.5608 7.86348 44.6603 7.32544 44.6603C6.49871 44.6603 5.70316 44.4238 5.02551 43.9776C3.84777 43.1819 3.16064 41.8984 3.16064 40.5289V8.72327H1.77282C1.00573 8.72327 0.38501 9.33882 0.38501 10.0995V45.882C0.38501 46.6427 1.00573 47.2582 1.77282 47.2582H28.2705C28.7906 49.6166 30.9072 51.3897 33.4391 51.3897C35.9699 51.3897 38.0864 49.6166 38.6065 47.2582H65.6123C66.3794 47.2582 67.0001 46.6427 67.0001 45.882V10.0995C67.0001 9.33882 66.3794 8.72327 65.6123 8.72327Z" fill="#3A3A3A"/>
        <path d="M6.5613 41.6845C6.93604 41.9323 7.43563 41.9736 7.85196 41.8083C16.0817 38.409 24.2698 38.2852 32.3052 41.4505V2.73676C23.8257 -0.304696 15.2212 -0.0570655 6.78331 3.47999C6.26983 3.70007 5.93677 4.1955 5.93677 4.74603V40.5285C5.93677 40.9964 6.17267 41.4231 6.5613 41.6845Z" fill="#3A3A3A"/>
    </svg>
    )
}

export default OpenSvg